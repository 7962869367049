import "./App.css";
import React from "react";
import "./assets/css/main.css";
import Header from "./Header";
import Footer from "./Footer";
import HomePage from "./HomePage";
import "bootstrap/dist/css/bootstrap.min.css";
import AccessControlSystemForm from "./MaintenanceForms/accessControlSystem/AccessControlSystemForm";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function App() {
  return (
    <div className="App">
      <div className="row">
        <Header />
      </div>
      <AccessControlSystemForm />
      <div className="row">
        <Footer />
      </div>
    </div>
  );
}

export default App;
