import "./App.css";
import React, { useState } from "react";
import "./assets/css/main.css";
import defaultLogo from "./assets//imgs/theme/logo-default.png";
import userIcon from "./assets/imgs/theme/icons/icon-user.svg";
import QuickLinkItem from "./QuickLinkItem";
import { fieldNames, languageChoices } from "./constants";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";

const linkData = [
  {
    id: "1",
    lastName: "Smith",
    firstName: "Jason",
    middleName: "P.",
    badgeNumber: 3242345,
    employer: "Delta Airlines",
    imageLink: "./images/thumbnail-1.jpg",
  },
  {
    id: "2",
    lastName: "Henderson",
    firstName: "Clara",
    middleName: "R.",
    badgeNumber: 8832344,
    employer: "American Airlines",
    imageLink: "./images/thumbnail-3.jpg",
  },
];

const currentVersion = "3.1.1 Released";

export default function Header() {
  const [language, setLanguage] = useState({
    id: 1,
    name: "English",
  });
  const [chosenFieldName, setChosenFieldName] = useState("SSN");
  const [fieldValue, setFieldValue] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  const menuButtonStyle = {
    border: "none",
    width: "40px",
    height: "40px",
  };

  function handleFormSubmit(e) {
    e.preventDefault();
    if (!fieldValue) return;

    var fieldToSearch = fieldNames.find((f) => f.fieldName === chosenFieldName);

    console.log(fieldToSearch);
    console.log(fieldValue);

    setChosenFieldName("Name");
    setFieldValue("");
  }

  return (
    <header className="header-area header-height-2 mb-12">
      <div className="header-top header-top-ptb-1 d-none d-lg-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-4">
              <div className="header-info">
                <ul>
                  <li>
                    {" "}
                    <button
                      className="pl-2"
                      variant="light"
                      style={menuButtonStyle}
                      onClick={handleShow}
                    >
                      <i class="fa fa-bars"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">(404) 490-3460</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4">
              <div className="text-center">
                <div id="news-flash" className="d-inline-block">
                  <ul>
                    <li>
                      <i className="fa fa-asterisk mr-5"></i>
                      <b className="text-danger">
                        IDGateway Version {currentVersion}
                      </b>{" "}
                      - See release notes.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="header-info header-info-right">
                <ul>
                  <li key="1">
                    <a className="language-dropdown-active" href="#">
                      {" "}
                      <i className="fa fa-globe-americas"></i> English{" "}
                      <i className="fa fa-chevron-down"></i>
                    </a>
                    <ul className="language-dropdown">
                      {languageChoices.map((language) => (
                        <li key={language.id}>
                          <a href="#">{language.name}</a>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li key="2">
                    <a href="page-login-register.html">Log In / Sign Up</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
        <div className="container">
          <div className="header-wrap header-space-between">
            <div className="logo logo-width-1">
              <a href="index.html">
                <img src={defaultLogo} alt="Logikbomb Logo" />
              </a>
            </div>
            <div className="search-style-2">
              <form onSubmit={handleFormSubmit}>
                <select
                  value={chosenFieldName}
                  onChange={(e) => setChosenFieldName(e.target.value)}
                  className="select-active"
                >
                  {fieldNames.map((field) => (
                    <option key={field.id}>{field.fieldName}</option>
                  ))}
                </select>
                <input
                  type="text"
                  placeholder="Search for ..."
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                />
                <button type="submit">
                  {" "}
                  <i className="fa fa-search"></i>{" "}
                </button>
              </form>
            </div>
            <div className="header-action-right">
              <div className="header-action-2">
                <div className="header-action-icon-2">
                  <a className="mini-cart-icon" href="shop-cart.html">
                    <img alt="wowy" src={userIcon} />
                    <span className="pro-count blue">{linkData.length}</span>
                  </a>
                  <div className="cart-dropdown-wrap cart-dropdown-hm2">
                    <ul>
                      {linkData.map((item) => (
                        <QuickLinkItem item={item} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom header-bottom-bg-color sticky-bar gray-bg sticky-blue-bg">
        <div className="container">
          <div className="header-wrap header-space-between position-relative"></div>
        </div>
      </div>
      <div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <div className="logo logo-width-1">
              <a href="index.html">
                <img src={defaultLogo} alt="Logikbomb Logo" />
              </a>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            Here you can find links and manu items that will enable you to
            maintain software settings.
            <NavDropdown
              className="pt-2"
              title="Access Control"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="#">
                Access Control System
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">Access Code</NavDropdown.Item>
              <NavDropdown.Item href="#">Access Group</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="pt-2" title="Badge" id="basic-nav-dropdown">
              <NavDropdown.Item href="#">Badge Status</NavDropdown.Item>
              <NavDropdown.Item href="#">Badge Type</NavDropdown.Item>
            </NavDropdown>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  );
}
