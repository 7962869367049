import "./App.css";
import React from "react";
import "./assets/css/main.css";

export default function QuickLinkItem({ item }) {
  return (
    <li key={item.id}>
      <div className="shopping-cart-img">
        <a href="shop-product-right.html">
          <img alt="wowy" src={item.imageLink} />
        </a>
      </div>
      <div className="shopping-cart-title">
        <h4>
          <a href="shop-product-right.html">
            {item.lastName + ", " + item.firstName + " " + item.middleName}
          </a>
        </h4>
        <h3>{item.employer}</h3>
        <h4>Badge #: {item.badgeNumber}</h4>
      </div>
      <div className="shopping-cart-delete">
        <a href="#">
          <i className="far fa-times"></i>
        </a>
      </div>
    </li>
  );
}
