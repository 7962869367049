export const languageChoices = [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Français",
  },
  {
    id: 3,
    name: "Deutsch",
  },
  {
    id: 4,
    name: "Espanol",
  },
];

export const fieldNames = [
  {
    id: "1",
    fieldName: "SSN",
    tableName: "Person",
    field: "SSN",
  },
  {
    id: "2",
    fieldName: "Badge #",
    tableName: "Badge",
    field: "BadgeNumber",
  },
  {
    id: "3",
    fieldName: "Last Name",
    tableName: "Person",
    field: "LastName",
  },
  {
    id: "4",
    fieldName: "First Name",
    tableName: "Person",
    field: "FirstName",
  },
  {
    id: "5",
    fieldName: "Date of Birth",
    tableName: "Person",
    field: "DateOfBirth",
  },
  {
    id: "6",
    fieldName: "Company Name",
    tableName: "Employer",
    field: "Name",
  },

  {
    id: "7",
    fieldName: "Badge Status",
    tableName: "Badge",
    field: "BadgeStatus.Description",
  },
  {
    id: "8",
    fieldName: "Badge Type",
    tableName: "Badge",
    field: "BadgeType.Description",
  },
  {
    id: "9",
    fieldName: "Key #",
    tableName: "Key",
    field: "KeySerialNumber",
  },
  {
    id: "10",
    fieldName: "Key Ring Identifier",
    tableName: "KeyRing",
    field: "Identifier",
  },
  {
    id: "11",
    fieldName: "Key Type",
    tableName: "Key",
    field: "KeyType.Description",
  },
  {
    id: "12",
    fieldName: "Vehicle Tag",
    tableName: "Vehicle",
    field: "TagNumber",
  },
  {
    id: "13",
    fieldName: "Employer Name",
    tableName: "Employer",
    field: "LastName",
  },
  {
    id: "14",
    fieldName: "ID #",
    tableName: "SourceDocument",
    field: "Number",
  },
  {
    id: "15",
    fieldName: "Vehicle Make",
    tableName: "Vehicle",
    field: "Make",
  },
  {
    id: "16",
    fieldName: "Vehicle Model",
    tableName: "Vehicle",
    field: "Model",
  },
  {
    id: "17",
    fieldName: "Vehicle Color",
    tableName: "Vehicle",
    field: "Color",
  },
];

export const eyeColor = [
  {
    id: "BLK",
    name: "Black",
  },
  {
    id: "BRO",
    name: "Brown",
  },
  {
    id: "GRN",
    name: "Green",
  },
  {
    id: "MAR",
    name: "Maroon",
  },
  {
    id: "PNK",
    name: "Pink",
  },
  {
    id: "BLU",
    name: "Blue",
  },
  {
    id: "GRY",
    name: "Gray",
  },
  {
    id: "HAZ",
    name: "Hazel",
  },
  {
    id: "MUL",
    name: "Multicolored",
  },
  {
    id: "XXX",
    name: "Unknown",
  },
];

export const hairColor = [
  {
    id: "BLD",
    name: "Bald",
  },
  {
    id: "BLK",
    name: "Black",
  },
  {
    id: "BLN",
    name: "Blond or Strawberry",
  },
  {
    id: "BLU",
    name: "Blue",
  },
  {
    id: "BRO",
    name: "Brown",
  },
  {
    id: "GRY",
    name: "Gray or Partially Gray",
  },
  {
    id: "GRN",
    name: "Green",
  },
  {
    id: "ONG",
    name: "Orange",
  },
  {
    id: "PNK",
    name: "Pink",
  },
  {
    id: "PLE",
    name: "Purple",
  },
  {
    id: "RED",
    name: "Red or Auburn",
  },
  {
    id: "SDY",
    name: "Sandy",
  },
  {
    id: "WHI",
    name: "White",
  },
  {
    id: "XXX",
    name: "Unknown",
  },
];

export const race = [
  {
    id: "I",
    name:
      "American Indian or Alaskan Native - a person having origins in any of the original peoples of the Americas and maintaining cultural identification through tribal affiliations or community recognition",
  },
  {
    id: "A",
    name:
      "Asian or Pacific Islander - a person having origins in any of the original peoples of the Far East, Southeast Asia, the Indian sub-continent or the Pacific Islands",
  },
  {
    id: "B",
    name:
      "Black - a person having origins in any of the black racial groups of Africa",
  },
  {
    id: "W",
    name:
      "White - a person having origins in any of the original peoples of Europe, North Africa, or Middle East",
  },
  {
    id: "U",
    name: "	Unknown",
  },
];

export const sex = [
  {
    id: "F",
    name: "Female",
  },
  {
    id: "M",
    name: "Male",
  },
  {
    id: "U",
    name: "Unknown",
  },
];

export const state = [
  {
    id: "AK",
    name: "Alaska",
  },
  {
    id: "AL",
    name: "Alabama",
  },
  {
    id: "AR",
    name: "Arkansas",
  },
  {
    id: "AS",
    name: "American Samoa",
  },
  {
    id: "AZ",
    name: "Arizona",
  },
  {
    id: "CA",
    name: "California",
  },
  {
    id: "CO",
    name: "Colorado",
  },
  {
    id: "CT",
    name: "Connecticut",
  },
  {
    id: "DC",
    name: "District of Columbia",
  },
  {
    id: "DE",
    name: "Delaware",
  },
  {
    id: "FL",
    name: "Florida",
  },
  {
    id: "FM",
    name: "Federated States of Micronesia",
  },
  {
    id: "GA",
    name: "Georgia",
  },
  {
    id: "GU",
    name: "Guam",
  },
  {
    id: "HI",
    name: "Hawaii",
  },
  {
    id: "IA",
    name: "Iowa",
  },
  {
    id: "ID",
    name: "Idaho",
  },
  {
    id: "IL",
    name: "Illinois",
  },
  {
    id: "IN",
    name: "Indiana",
  },
  {
    id: "KS",
    name: "Kansas",
  },
  {
    id: "KY",
    name: "Kentucky",
  },
  {
    id: "LA",
    name: "Louisiana",
  },
  {
    id: "MA",
    name: "Massachusetts",
  },
  {
    id: "MD",
    name: "Maryland",
  },
  {
    id: "ME",
    name: "Maine",
  },
  {
    id: "MH",
    name: "Marhshall Islands",
  },
  {
    id: "MI",
    name: "Michigan",
  },
  {
    id: "MN",
    name: "Minnesota",
  },
  {
    id: "MO",
    name: "Missouri",
  },
  {
    id: "MP",
    name: "Northern Mariana Islands",
  },
  {
    id: "MS",
    name: "Mississippi",
  },
  {
    id: "MT",
    name: "Montana",
  },
  {
    id: "NC",
    name: "North Carolina",
  },
  {
    id: "ND",
    name: "North Dakota",
  },
  {
    id: "NE",
    name: "Nebraska",
  },
  {
    id: "NH",
    name: "New Hampshire",
  },
  {
    id: "NJ",
    name: "New Jersey",
  },
  {
    id: "NM",
    name: "New Mexico",
  },
  {
    id: "NV",
    name: "Nevada",
  },
  {
    id: "NY",
    name: "New York",
  },
  {
    id: "OH",
    name: "Ohio",
  },
  {
    id: "OK",
    name: "Oklahoma",
  },
  {
    id: "OR",
    name: "Oregon",
  },
  {
    id: "PN",
    name: "Pennsylvania",
  },
  {
    id: "PR",
    name: "Puerto Rico",
  },
  {
    id: "PW",
    name: "Palau",
  },
  {
    id: "RI",
    name: "Rhode Island",
  },
  {
    id: "SC",
    name: "South Carolina",
  },
  {
    id: "SD",
    name: "South Dakota",
  },
  {
    id: "TN",
    name: "Tennessee",
  },
  {
    id: "TX",
    name: "Texas",
  },
  {
    id: "UM",
    name:
      "Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Islands, Navassa Island, Palmyra Atoll, U.S. Minor Outlying Islands, Wake Island",
  },
  {
    id: "UT",
    name: "Utah",
  },
  {
    id: "VA",
    name: "Virginia",
  },
  {
    id: "VI",
    name: "Virgin Islands of the U.S.",
  },
  {
    id: "VT",
    name: "Vermont",
  },
  {
    id: "WA",
    name: "Washington",
  },
  {
    id: "WI",
    name: "Wisconsin",
  },
  {
    id: "WV",
    name: "West Virginia",
  },
  {
    id: "WY",
    name: "Wyoming",
  },
];

export const country = [
  {
    id: "US",
    name: "United States",
  },
  {
    id: "AA",
    name: "Aruba",
  },
  {
    id: "AC",
    name: "Antigua and Barbuda",
  },
  {
    id: "AE",
    name: "United Arab Emirates",
  },
  {
    id: "AF",
    name: "Afghanistan",
  },
  {
    id: "AG",
    name: "Algeria",
  },
  {
    id: "AJ",
    name: "Azerbaijan",
  },
  {
    id: "AL",
    name: "Albania",
  },
  {
    id: "AM",
    name: "Armenia",
  },
  {
    id: "AN",
    name: "Andorra",
  },
  {
    id: "AO",
    name: "Angola",
  },
  {
    id: "AQ",
    name: "American Samoa",
  },
  {
    id: "AR",
    name: "Argentina",
  },
  {
    id: "AS",
    name: "Austrailia",
  },
  {
    id: "AT",
    name: "Ashmore and Cartier Islands",
  },
  {
    id: "AU",
    name: "Austria",
  },
  {
    id: "AV",
    name: "Anguilla",
  },
  {
    id: "AY",
    name: "Antarctica",
  },
  {
    id: "BA",
    name: "Bahrain",
  },
  {
    id: "BB",
    name: "Barbados",
  },
  {
    id: "BC",
    name: "Botswana",
  },
  {
    id: "BD",
    name: "Bermuda",
  },
  {
    id: "BE",
    name: "Belgium",
  },
  {
    id: "BF",
    name: "Bahamas, The",
  },
  {
    id: "BG",
    name: "Bangladesh",
  },
  {
    id: "BH",
    name: "Belize",
  },
  {
    id: "BK",
    name: "Bosnia and Herzegovinia",
  },
  {
    id: "BL",
    name: "Bolivia",
  },
  {
    id: "BM",
    name: "Burma",
  },
  {
    id: "BN",
    name: "Benin",
  },
  {
    id: "BO",
    name: "Belarus",
  },
  {
    id: "BP",
    name: "Solomon Islands",
  },
  {
    id: "BQ",
    name: "Navassa Island",
  },
  {
    id: "BR",
    name: "Brazil",
  },
  {
    id: "BS",
    name: "Bassas Da India",
  },
  {
    id: "BT",
    name: "Bhutan",
  },
  {
    id: "BU",
    name: "Bulgaria",
  },
  {
    id: "BV",
    name: "Bouvet Island",
  },
  {
    id: "BX",
    name: "Brunei",
  },
  {
    id: "BY",
    name: "Burundi",
  },
  {
    id: "CA",
    name: "Canada",
  },
  {
    id: "CB",
    name: "Cambodia",
  },
  {
    id: "CD",
    name: "Chad",
  },
  {
    id: "CE",
    name: "Sri Lanka",
  },
  {
    id: "CF",
    name: "Congo",
  },
  {
    id: "CG",
    name: "Congo, Democratic Republic of the",
  },
  {
    id: "CH",
    name: "China",
  },
  {
    id: "CI",
    name: "Chile",
  },
  {
    id: "CJ",
    name: "Cayman Islands",
  },
  {
    id: "CK",
    name: "Cocos (Keeling) Islands",
  },
  {
    id: "CM",
    name: "Cameroon",
  },
  {
    id: "CN",
    name: "Comoros",
  },
  {
    id: "CO",
    name: "Columbia",
  },
  {
    id: "CQ",
    name: "Northern Mariana Islands",
  },
  {
    id: "CR",
    name: "Coral Sea Islands",
  },
  {
    id: "CS",
    name: "Costa Rica",
  },
  {
    id: "CT",
    name: "Central African Republic",
  },
  {
    id: "CU",
    name: "Cuba",
  },
  {
    id: "CV",
    name: "Cape Verde",
  },
  {
    id: "CW",
    name: "Cook Islands",
  },
  {
    id: "CY",
    name: "Cyprus",
  },
  {
    id: "DA",
    name: "Denmark",
  },
  {
    id: "DJ",
    name: "Djibouti",
  },
  {
    id: "DO",
    name: "Dominica",
  },
  {
    id: "DQ",
    name: "Jarvis Island",
  },
  {
    id: "DR",
    name: "Dominican Republic",
  },
  {
    id: "EC",
    name: "Ecuador",
  },
  {
    id: "EG",
    name: "Egypt",
  },
  {
    id: "EI",
    name: "Ireland",
  },
  {
    id: "EK",
    name: "Equatorial Guinea",
  },
  {
    id: "EN",
    name: "Estonia",
  },
  {
    id: "ER",
    name: "Eritrea",
  },
  {
    id: "ES",
    name: "El Savador",
  },
  {
    id: "ET",
    name: "Ethiopia",
  },
  {
    id: "EU",
    name: "Europa Island",
  },
  {
    id: "EZ",
    name: "Czech Republic",
  },
  {
    id: "FG",
    name: "French Guiana",
  },
  {
    id: "FI",
    name: "Finland",
  },
  {
    id: "FJ",
    name: "Fiji",
  },
  {
    id: "FK",
    name: "Falkland Islands (Islas Malvinas)",
  },
  {
    id: "FM",
    name: "Micronesia, Federated States of",
  },
  {
    id: "FO",
    name: "Faroe Islands",
  },
  {
    id: "FP",
    name: "French Polynesia",
  },
  {
    id: "FQ",
    name: "Baker Island",
  },
  {
    id: "FR",
    name: "France",
  },
  {
    id: "FS",
    name: "French Southern and Antartic Lands",
  },
  {
    id: "GA",
    name: "Gambia, The",
  },
  {
    id: "GB",
    name: "Gabon",
  },
  {
    id: "GG",
    name: "Georgia",
  },
  {
    id: "GH",
    name: "Ghana",
  },
  {
    id: "GI",
    name: "Gibraltar",
  },
  {
    id: "GJ",
    name: "Grenada",
  },
  {
    id: "GK",
    name: "Guernsey",
  },
  {
    id: "GL",
    name: "Greenland",
  },
  {
    id: "GM",
    name: "Germany",
  },
  {
    id: "GO",
    name: "Glorioso Islands",
  },
  {
    id: "GP",
    name: "Guadeloupe",
  },
  {
    id: "GQ",
    name: "Guam",
  },
  {
    id: "GR",
    name: "Greece",
  },
  {
    id: "GT",
    name: "Guatemala",
  },
  {
    id: "GV",
    name: "Guinea",
  },
  {
    id: "GY",
    name: "Guyana",
  },
  {
    id: "GZ",
    name: "Gaza Strip",
  },
  {
    id: "HA",
    name: "Haiti",
  },
  {
    id: "HK",
    name: "Hong Kong",
  },
  {
    id: "HM",
    name: "Heard Island and McDonald Islands",
  },
  {
    id: "HO",
    name: "Honduras",
  },
  {
    id: "HQ",
    name: "Howland Island",
  },
  {
    id: "HR",
    name: "Croatia",
  },
  {
    id: "HU",
    name: "Hungary",
  },
  {
    id: "IC",
    name: "Iceland",
  },
  {
    id: "ID",
    name: "Indonesia",
  },
  {
    id: "IM",
    name: "Isle of Man",
  },
  {
    id: "IN",
    name: "India",
  },
  {
    id: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    id: "IP",
    name: "Clipperton Island",
  },
  {
    id: "IR",
    name: "Iran",
  },
  {
    id: "IS",
    name: "Israel",
  },
  {
    id: "IT",
    name: "Italy",
  },
  {
    id: "IV",
    name: "Cote D'Ivoire",
  },
  {
    id: "IZ",
    name: "Iraq",
  },
  {
    id: "JA",
    name: "Japan",
  },
  {
    id: "JE",
    name: "Jersey",
  },
  {
    id: "JM",
    name: "Jamaica",
  },
  {
    id: "JN",
    name: "Jan Mayen",
  },
  {
    id: "JO",
    name: "Jordan",
  },
  {
    id: "JQ",
    name: "Johnson Atoll",
  },
  {
    id: "JU",
    name: "Juan De Nova Island",
  },
  {
    id: "KE",
    name: "Kenya",
  },
  {
    id: "KG",
    name: "Kyrgyzstan",
  },
  {
    id: "KN",
    name: "North Korea",
  },
  {
    id: "KQ",
    name: "Kingman Reef",
  },
  {
    id: "KR",
    name: "Kiribati",
  },
  {
    id: "KS",
    name: "South Korea",
  },
  {
    id: "KT",
    name: "Christmas Island",
  },
  {
    id: "KU",
    name: "Kuwait",
  },
  {
    id: "KZ",
    name: "Kazakhstan",
  },
  {
    id: "LA",
    name: "Laos",
  },
  {
    id: "LE",
    name: "Lebanon",
  },
  {
    id: "LG",
    name: "Latvia",
  },
  {
    id: "LH",
    name: "Lithuania",
  },
  {
    id: "LI",
    name: "Liberia",
  },
  {
    id: "LO",
    name: "Slovakia",
  },
  {
    id: "LQ",
    name: "Palmyra Atoll",
  },
  {
    id: "LS",
    name: "Liechtenstein",
  },
  {
    id: "LT",
    name: "Lesotho",
  },
  {
    id: "LU",
    name: "Luxembourg",
  },
  {
    id: "LY",
    name: "Lybia",
  },
  {
    id: "MA",
    name: "Madagascar",
  },
  {
    id: "MB",
    name: "Martinique",
  },
  {
    id: "MC",
    name: "Macau",
  },
  {
    id: "MD",
    name: "Moldova",
  },
  {
    id: "MF",
    name: "Mayotte",
  },
  {
    id: "MG",
    name: "Mongolia",
  },
  {
    id: "MH",
    name: "Montserrat",
  },
  {
    id: "MI",
    name: "Malawi",
  },
  {
    id: "MJ",
    name: "Montenegro",
  },
  {
    id: "MK",
    name: "Macedonia",
  },
  {
    id: "ML",
    name: "Mali",
  },
  {
    id: "MN",
    name: "Moneco",
  },
  {
    id: "MO",
    name: "Morocco",
  },
  {
    id: "MP",
    name: "Mauritius",
  },
  {
    id: "MQ",
    name: "Midway Islands",
  },
  {
    id: "MR",
    name: "Mauritania",
  },
  {
    id: "MT",
    name: "Malta",
  },
  {
    id: "MU",
    name: "Oman",
  },
  {
    id: "MV",
    name: "Maldives",
  },
  {
    id: "MX",
    name: "Mexico",
  },
  {
    id: "MY",
    name: "Malaysia",
  },
  {
    id: "MZ",
    name: "Mozambique",
  },
  {
    id: "NC",
    name: "New Caledonia",
  },
  {
    id: "NE",
    name: "Niue",
  },
  {
    id: "NF",
    name: "Norfolk Island",
  },
  {
    id: "NG",
    name: "Niger",
  },
  {
    id: "NH",
    name: "Vanuatu",
  },
  {
    id: "NI",
    name: "Nigeria",
  },
  {
    id: "NL",
    name: "Netherlands",
  },
  {
    id: "NM",
    name: "No Man's Land",
  },
  {
    id: "NO",
    name: "Norway",
  },
  {
    id: "NP",
    name: "Nepal",
  },
  {
    id: "NR",
    name: "Nauru",
  },
  {
    id: "NS",
    name: "Suriname",
  },
  {
    id: "NT",
    name: "Netherlands Antilles",
  },
  {
    id: "NU",
    name: "Nicaragua",
  },
  {
    id: "NZ",
    name: "New Zealand",
  },
  {
    id: "OS",
    name: "Oceans",
  },
  {
    id: "PA",
    name: "Paraguay",
  },
  {
    id: "PC",
    name: "Pitcairn Islands",
  },
  {
    id: "PE",
    name: "Peru",
  },
  {
    id: "PF",
    name: "Paracel Islands",
  },
  {
    id: "PG",
    name: "Spratly Islands",
  },
  {
    id: "PK",
    name: "Pakistan",
  },
  {
    id: "PL",
    name: "Poland",
  },
  {
    id: "PM",
    name: "Panama",
  },
  {
    id: "PO",
    name: "Portugal",
  },
  {
    id: "PP",
    name: "Papua New Guinea",
  },
  {
    id: "PS",
    name: "Palau",
  },
  {
    id: "PU",
    name: "Guinea-Bissau",
  },
  {
    id: "QA",
    name: "Qatar",
  },
  {
    id: "RB",
    name: "Serbia",
  },
  {
    id: "RE",
    name: "Reunion",
  },
  {
    id: "RM",
    name: "Marshall Islands",
  },
  {
    id: "RO",
    name: "Romania",
  },
  {
    id: "RP",
    name: "Philippines",
  },
  {
    id: "RQ",
    name: "Puerto Rico",
  },
  {
    id: "RS",
    name: "Russia",
  },
  {
    id: "RW",
    name: "Rwanda",
  },
  {
    id: "SA",
    name: "Saudi Arabia",
  },
  {
    id: "SB",
    name: "Saint Pierre and Miquelon",
  },
  {
    id: "SC",
    name: "Saint Kitts and Nevis",
  },
  {
    id: "SE",
    name: "Seychelles",
  },
  {
    id: "SF",
    name: "South Africa",
  },
  {
    id: "SG",
    name: "Senegal",
  },
  {
    id: "SH",
    name: "Saint Helena",
  },
  {
    id: "SI",
    name: "Slovenia",
  },
  {
    id: "SL",
    name: "Sierra Leone",
  },
  {
    id: "SM",
    name: "San Marino",
  },
  {
    id: "SN",
    name: "Singapore",
  },
  {
    id: "SO",
    name: "Somalia",
  },
  {
    id: "SP",
    name: "Spain",
  },
  {
    id: "ST",
    name: "Saint Lucia",
  },
  {
    id: "SU",
    name: "Sudan",
  },
  {
    id: "SV",
    name: "Svalbard",
  },
  {
    id: "SW",
    name: "Sweden",
  },
  {
    id: "SX",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    id: "SY",
    name: "Syria",
  },
  {
    id: "SZ",
    name: "Switzerland",
  },
  {
    id: "TD",
    name: "Trinidad and Tobago",
  },
  {
    id: "TE",
    name: "Tromelin Island",
  },
  {
    id: "TH",
    name: "Thailand",
  },
  {
    id: "TI",
    name: "Tajikistan",
  },
  {
    id: "TK",
    name: "Turks and Caicos Islands",
  },
  {
    id: "TL",
    name: "Tokelau",
  },
  {
    id: "TN",
    name: "Tonga",
  },
  {
    id: "TO",
    name: "Togo",
  },
  {
    id: "TP",
    name: "Sao Tome and Principe",
  },
  {
    id: "TS",
    name: "Tunisia",
  },
  {
    id: "TT",
    name: "East Timor",
  },
  {
    id: "TU",
    name: "Turkey",
  },
  {
    id: "TV",
    name: "Tuvalu",
  },
  {
    id: "TW",
    name: "Taiwan",
  },
  {
    id: "TX",
    name: "Turkmenistan",
  },
  {
    id: "TZ",
    name: "Tanzania",
  },
  {
    id: "UF",
    name: "Undersea Features",
  },
  {
    id: "UG",
    name: "Uganda",
  },
  {
    id: "UK",
    name: "United Kingdom",
  },
  {
    id: "UP",
    name: "Ukraine",
  },
  {
    id: "UV",
    name: "Burkina Faso",
  },
  {
    id: "UY",
    name: "Uruguay",
  },
  {
    id: "UZ",
    name: "Uzbekistan",
  },
  {
    id: "VC",
    name: "Saint Vincent and the Grenadines",
  },
  {
    id: "VE",
    name: "Venezuela",
  },
  {
    id: "VI",
    name: "British Virgin Islands",
  },
  {
    id: "VM",
    name: "Vietnam",
  },
  {
    id: "VQ",
    name: "United States Virgin Islands",
  },
  {
    id: "VT",
    name: "Vatican City",
  },
  {
    id: "WA",
    name: "Namibia",
  },
  {
    id: "WE",
    name: "West Bank",
  },
  {
    id: "WF",
    name: "Wallis and Futuna",
  },
  {
    id: "WI",
    name: "Western Sahara",
  },
  {
    id: "WQ",
    name: "Wake Island",
  },
  {
    id: "WS",
    name: "Samoa",
  },
  {
    id: "WZ",
    name: "Swaziland",
  },
  {
    id: "YM",
    name: "Yemen",
  },
  {
    id: "ZA",
    name: "Zambia",
  },
  {
    id: "ZI",
    name: "Zimbabwe",
  },
];
