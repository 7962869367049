import "./App.css";
import React, { useState } from "react";
import "./assets/css/main.css";

export default function Header() {
  return (
    <footer className="main mt-2">
      <div className="container pb-20 wow fadeIn animated">
        <div className="row">
          <div className="col-12 mb-20">
            <div className="footer-bottom"></div>
          </div>
          <div className="text-left text-start col-lg-6">
            <p className="font-sm text-muted mb-0">
              &copy; 2023,{" "}
              <strong className="text-brand">LogikBomb, LLC</strong>
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-lg-end text-start font-sm text-muted mb-0">
              All rights reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
