import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";

//spread operator

//const [items, setItems] = useState([]);

//function handleAddItems(item) {
//setItems((items)=>[...items, item])
//}

//pass handler down just like you would pass any other prop

//function handleDeleteItem(id){
//setItems((items)=>items.filter(item=> item.id !== id))
//}

//<button onClick={() => onDeleteItem(item.id)}>X</button>

//function handleToggleItem(id) {
//setItems((items)=>items.map(item => item.id === id ? {...item, packed: !item.packed} : item)};
//}

const acsData = [
  {
    id: "96449AA1-7259-4C8E-9D2F-9FD0D06E1E83",
    description: "Admin Building",
    okToUse: true,
    ccure900: false,
    ccure9000: false,
    diamond: false,
    amag: true,
  },
  {
    id: "9B14A108-4057-4437-911B-3FDDF1B77E9A",
    description: "Annex CCure 9000",
    okToUse: true,
    ccure900: false,
    ccure9000: true,
    diamond: false,
    amag: false,
  },
  {
    id: "2050B8AA-78E8-44F4-B220-13AE3EB950C2",
    description: "FAA Control Tower",
    okToUse: true,
    ccure900: false,
    ccure9000: true,
    diamond: false,
    amag: false,
  },
  {
    id: "097F60CE-36A6-4163-B8EE-EE9E61BA3D6B",
    description: "Main Concourse",
    okToUse: true,
    ccure900: false,
    ccure9000: true,
    diamond: false,
    amag: false,
  },
];

export default function AccessControlSystemForm() {
  const [records, setRecords] = useState(acsData);

  const [currentRecord, setCurrentRecord] = useState({
    id: "96449AA1-7259-4C8E-9D2F-9FD0D06E1E83",
    description: "Admin Building",
    okToUse: true,
    ccure900: false,
    ccure9000: false,
    diamond: false,
    amag: true,
  });

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(row);
    },
    onMouseEnter: (e, row, rowIndex) => {
      console.log(row);
    },
  };

  const [id, setId] = useState("96449AA1-7259-4C8E-9D2F-9FD0D06E1E83");
  const [desc, setDesc] = useState("Admin Building");
  const [okToUse, setOkToUse] = useState(true);
  const [ccure900, setCCure900] = useState(false);
  const [ccure9000, setCCure9000] = useState(false);
  const [diamond, setDiamond] = useState(false);
  const [amag, setAmag] = useState(true);

  function handleRowClick(item) {
    setCurrentRecord(item);
    setId(item.id);
    setDesc(item.description);
    setOkToUse(item.okToUse);
    setCCure900(item.ccure900);
    setCCure9000(item.ccure9000);
    setDiamond(item.diamond);
    setAmag(item.amag);
  }

  function handleSubmit(e) {
    e.preventDefault();
    var updateRow = {
      id: id,
      description: desc,
      okToUse: okToUse,
      ccure900: ccure900,
      ccure9000: ccure9000,
      diamond: diamond,
      amag: amag,
    };

    console.log(updateRow);

    setCurrentRecord(updateRow);

    const newRows = records.slice();
    var rowToUpdate = newRows.findIndex((r) => r.id === id);
    console.log("row to update: " + rowToUpdate);
    newRows[rowToUpdate] = updateRow;
    console.log(newRows);
    setRecords(newRows);
  }

  return (
    <Container fluid>
      <Row>
        <div className="container-fluid pl-2">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Access Control Systems</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Row>
      <Row>
        <Col md="8" className="text-start">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Description</th>
                <th>Ok To Use</th>
                <th>CCure 900</th>
                <th>CCure 9000</th>
                <th>Diamond</th>
                <th>Symmetry</th>
              </tr>
            </thead>
            <tbody>
              {records.map((e) => (
                <>
                  <tr key={e.id} onClick={() => handleRowClick(e)}>
                    <td>{e.description}</td>
                    <td>{e.okToUse ? "✅" : "❌"}</td>
                    <td>{e.ccure900 ? "✅" : "❌"}</td>
                    <td>{e.ccure9000 ? "✅" : "❌"}</td>
                    <td>{e.diamond ? "✅" : "❌"}</td>
                    <td>{e.amag ? "✅" : "❌"}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col md="4" className="text-start">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
              <Form.Text className="text-muted">
                Easy identifier for this access control system.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="okToUseCheck">
              <Form.Check
                checked={okToUse}
                type="switch"
                label="Ok to Use"
                onChange={() => setOkToUse(!okToUse)}
              />
              <Form.Text className="text-muted">
                If unchecked the access control system will not appear to be
                used.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="acsType">
              <div key="acsType">
                <Form.Check
                  checked={ccure900}
                  name="acsTypeDesignation"
                  type="radio"
                  label="CCure 900"
                  controlId="ccure900"
                  onChange={() => {
                    if (!ccure900) {
                      setCCure900(true);
                      setCCure9000(false);
                      setAmag(false);
                      setDiamond(false);
                    }
                  }}
                ></Form.Check>
                <Form.Check
                  checked={ccure9000}
                  name="acsTypeDesignation"
                  type="radio"
                  label="CCure 9000"
                  controlId="ccure9000"
                  onChange={() => {
                    if (!ccure9000) {
                      setCCure9000(true);
                      setCCure900(false);
                      setDiamond(false);
                      setAmag(false);
                    }
                  }}
                ></Form.Check>
                <Form.Check
                  checked={diamond}
                  name="acsTypeDesignation"
                  type="radio"
                  label="Diamond"
                  controlId="diamond"
                  onChange={() => {
                    if (!diamond) {
                      setDiamond(true);
                      setCCure900(false);
                      setCCure9000(false);
                      setAmag(false);
                    }
                  }}
                ></Form.Check>
                <Form.Check
                  checked={amag}
                  name="acsTypeDesignation"
                  type="radio"
                  label="AMAG Symmetry"
                  controlId="AMAG"
                  onChange={() => {
                    if (!amag) {
                      setAmag(true);
                      setCCure900(false);
                      setCCure9000(false);
                      setDiamond(false);
                    }
                  }}
                ></Form.Check>
              </div>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
